// get方法
import axios from 'axios'
import qs from 'qs'
import Router from '../router/index.js'
import store from '../vuex/store'
import {
	Message,
	MessageBox,
	Loading
} from 'element-ui'
let loadingCount = 0;
let loading;



const httpAxios = axios.create(); //创建实例
let Config = {
	TIMEOUT: 6000, //设置超时时间为6秒
	baseURL: {
		dev: window.BASEURL_01,
		prod: ''
	}
};
// axios 配置
httpAxios.defaults.timeout = Config.TIMEOUT;
httpAxios.interceptors.response.use(
	response => {
		console.log(11)
		return response;
	},
	error => {
		console.log(error)
		if (error.message.includes('timeout')) { // 判断请求异常信息中是否含有超时timeout字符串
			Message({
				message: '请求超时，请稍后再试',
				type: 'error'
			});
			return Promise.reject(error); // reject这个错误信息
		}
		Message({
			message: '网络连接失败，请稍后再试',
			type: 'error'
		});
		return Promise.reject(error);
	});

export function get(url, params) {
	loading = Loading.service({
		lock: true,
		text: '加载中……',
		background: 'rgba(0, 0, 0, 0.7)'
	});
	let token = JSON.parse(this.$store.state.logindata.Token);
	return new Promise((resolve, reject) => {
		let url1 = "https://rhdzg.ronghengzn.com/api/" + url
		console.log(url)
		axios.get(url1, {
			params: params,
			headers: {
				'Authorization': 'Bearer ' + token,
				"Access-Control-Allow-Origin": "*"
			},
			timeout: 30000
		}).then(res => {
			loading.close();
			resolve(res.data);
		}).catch(err => {
			reject(err.data)
		})
	});
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
	let _this = this
	loading = Loading.service({
		lock: true,
		text: '加载中……',
		background: 'rgba(0, 0, 0, 0.7)'
	});
	return new Promise((resolve, reject) => {
		// console.log(params)
		// beforeSend: function(request) {
		//            request.setRequestHeader("Authorization", token);
		//        },
		let token = JSON.parse(sessionStorage.getItem('token'));
		let url1 = "https://rhdzg.ronghengzn.com/api/" + url
		// console.log(params)
		axios.post(url1, params, {
				headers: {
					'Authorization': 'Bearer ' + token,
				},
				timeout: 30000
			}, )
			.then(res => {
				resolve(res.data);
				loading.close();
				// console.log(res.data)
				// alert("验证过期,请重新登录")
				return res.data
			})
			.catch(function(error) {
				reject(error)
				if (error.response) {
					// if (error.response.status == 401 || error.response.status == 403) {
					//   loading.close();
					//   MessageBox.confirm(
					//     '会话已失效，请重新登录。',
					//     '提示', {
					//       confirmButtonText: '重新登录',
					//       type: "info"
					//     }
					//   ).then(() => {
					//     Router.push('/')
					//   }).catch(() => {
					//     Router.push('/')
					//   })

					// }
					if (error.response.status == 500) {
						loading.close();
						Message({
							message: '服务器错误',
							type: 'error'
						});

					}
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
				console.log(error.config);
			})
	});
}
let _this = this
export function Imgpost(formData) {
	return new Promise((resolve, reject) => {
		console.log(store);
		console.log(11111111111);
		console.log(formData);
		let url = "https://rhznjxcph.ronghengzn.com/api/UploadS?ID=" + store.state.logindata.ID + "&Count=0"
		console.log()
		axios({
			url: url,
			method: 'post',
			data: formData,
			processData: false, // 告诉axios不要去处理发送的数据(重要参数)
			contentType: false, // 告诉axios不要去设置Content-Type请求头
		}).then((res) => {
			console.log(res)
			resolve(res.data);
			loading.close();

			return res.data
		}).catch(function(error) {
			reject(error)
			if (error.response) {
				if (error.response.status == 500) {

					let URLa = "https://rhznjxcph.ronghengzn.com/api/SelectFile?ID=" + this.$store.state
						.logindata.ID
					axios({
						url: URLa,
						method: 'post',
						data: {},
					}).then((resa) => {
						console.log(resa)
						Vuex.commit("AddFileData", resa.data)
					})
					// Message({
					//   message: '文件上传失败',
					//   type: 'error'
					// });
					return 500
				}
			}
		})
	});
}
export function Tpost(url, params) {
	let _this = this
	loading = Loading.service({
		lock: true,
		text: '加载中……',
		background: 'rgba(0, 0, 0, 0.7)'
	});
	return new Promise((resolve, reject) => {
		// console.log(params)
		// beforeSend: function(request) {
		//            request.setRequestHeader("Authorization", token);
		//        },
		let token = JSON.parse(sessionStorage.getItem('token'));
		let url1 = "https://timely.ronghengzn.com/" + url
		// console.log(params)
		axios.post(url1, params, {
				headers: {
					'Authorization': 'Bearer ' + token,
				},
				timeout: 30000
			}, )
			.then(res => {
				resolve(res.data);
				loading.close();
				// console.log(res.data)
				// alert("验证过期,请重新登录")
				return res.data
			})
			.catch(function(error) {
				reject(error)
				if (error.response) {
					// if (error.response.status == 401 || error.response.status == 403) {
					//   loading.close();
					//   MessageBox.confirm(
					//     '会话已失效，请重新登录。',
					//     '提示', {
					//       confirmButtonText: '重新登录',
					//       type: "info"
					//     }
					//   ).then(() => {
					//     Router.push('/')
					//   }).catch(() => {
					//     Router.push('/')
					//   })

					// }
					if (error.response.status == 500) {
						loading.close();
						Message({
							message: '服务器错误',
							type: 'error'
						});

					}
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
				console.log(error.config);
			})
	});
}
